import Link from "next/link";
import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Copyright = () => {
  return (
    <>
      <div className="copyright-area copyright-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="copyright-left">
                <ul className="ft-menu link-hover">
                  <li>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href="/terms-of-use">Terms Of Use</Link>
                  </li>
                  <li>
                    <Link href="/pre-information-form">
                      Pre-Information Form
                    </Link>
                  </li>
                  <li>
                    <Link href="/distance-sales-agreement">
                      Distance Sales Agreement
                    </Link>
                  </li>
                  <li>
                    <Link href="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="copyright-right text-center text-lg-end">
                <p className="copyright-text">
                  Copyright © 2024
                  <Link href="#" className="btn-read-more ps-2">
                    <span>NISUS AI</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Footer Info Section */}
          <div className="row align-items-center footer-info mt-4">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
              <div className="single-info">
                <FaPhoneAlt className="me-2" style={{ color: "white" }} />
                <a href="tel:+905370507937">
                  <span>+90 537 050 79 37</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
              <div className="single-info">
                <FaEnvelope className="me-2" style={{ color: "white" }} />
                <a href="mailto:info@nisus-ai.com">
                  <span>info@nisus-ai.com</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-left">
              <div className="single-info">
                <FaMapMarkerAlt className="me-2" style={{ color: "white" }} />
                <a>
                  <span>
                    Adnan Kahveci Mah. Çamlıtepe Cad. <br />
                    BEYLİKDÜZÜ/İSTANBUL
                  </span>
                </a>
              </div>
            </div>
          </div>

          {/* Footer Images Section */}
          <div className="footer-images">
            <div className="footer-image-left">
              <img src="/images/paytr/paytr-1-left.jpg" alt="Left Image" />
            </div>
            <div className="footer-image-right" style={{ marginTop: 25 }}>
              <img src="/images/paytr/paytr-1-right.jpg" alt="Right Image" />
            </div>
            <div className="footer-image-full">
              <img src="/images/paytr/paytr-2-full.jpg" alt="Full Width Image" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .footer-images {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 20px;
          background-color: white;
        }
        .footer-image-left,
        .footer-image-right {
          width: 48%;
        }
        .footer-image-left img,
        .footer-image-right img,
        .footer-image-full img {
          width: 100%;
          height: auto;
        }
        .footer-image-full {
          width: 100%;
          margin-top: 20px;
        }

        @media (max-width: 768px) {
          .footer-image-left,
          .footer-image-right {
            width: 100%;
          }
          .footer-image-full {
            margin-top: 10px;
          }
        }
        .footer-info {
          padding-top: 5px;
        }
        .single-info {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #555;
        }
        .single-info span {
          display: inline-block;
          vertical-align: middle;
          max-width: 350px;
        }
        @media (max-width: 768px) {
          .single-info span {
            max-width: 150px;
          }
        }
        .me-2 {
          margin-right: 8px;
        }
        
        /* Hide Footer Info Section on Mobile */
        @media (max-width: 768px) {
          .footer-info {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default Copyright;
