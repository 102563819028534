import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import logo from "../../public/images/logo/logo.png";

const Header = ({ headerTransparent, headerSticky, closeSticky = false }) => {
  const [isSticky, setIsSticky] = useState(closeSticky);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const maxHeight = closeSticky ? -1 : 200;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > maxHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header
        className={`rainbow-header header-default ${headerTransparent} ${headerSticky} ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-6 logo-container">
              <div className="logo">
                <Link href="/">
                  <Image
                    className="logo-light"
                    src={logo}
                    width={201}
                    height={35}
                    alt="ChatBot Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-6 position-static d-none d-lg-flex justify-content-end">
              <div className="header-right">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li>
                      <Link href="/announcements">Announcements</Link>
                    </li>
                    <li>
                      <Link href="/release-notes">Releases</Link>
                    </li>
                    {/*<li>
                      <Link href="/public-assistants">Assistants</Link>
                    </li>*/}
                    <li>
                      <Link href="/about">About</Link>
                    </li>
                    <li>
                      <Link href="/contact-us">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-6 d-lg-none text-right mobile-menu-container">
              <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                <span
                  className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}
                ></span>
                <span
                  className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}
                ></span>
                <span
                  className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}
                ></span>
              </button>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-menu d-lg-none" style={{ marginTop: 20}}>
            <ul className="mobile-menu-list">
              <li>
                <Link href="/announcements">Announcements</Link>
              </li>
              <li>
                <Link href="/release-notes">Releases</Link>
              </li>
              {/*<li>
                <Link href="/public-assistants">Assistants</Link>
              </li>*/}
              <li>
                <Link href="/about">About</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>
        )}
      </header>
      <style jsx>{`
        @media (max-width: 991px) {
          .logo-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .mobile-menu-container {
            display: flex;
            justify-content: flex-end;
          }
          .mobile-menu-toggle {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            position: relative;
            width: 30px;
            height: 24px;
            transform: rotate(0deg);
            transition: all 0.5s ease-in-out;
          }
          .mobile-menu-toggle .icon-bar {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #ffffff;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
          }
          .mobile-menu-toggle .icon-bar:nth-child(1) {
            top: 0px;
          }
          .mobile-menu-toggle .icon-bar:nth-child(2) {
            top: 10px;
          }
          .mobile-menu-toggle .icon-bar:nth-child(3) {
            top: 20px;
          }
          .mobile-menu-toggle .icon-bar.open:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
          }
          .mobile-menu-toggle .icon-bar.open:nth-child(2) {
            opacity: 0;
          }
          .mobile-menu-toggle .icon-bar.open:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
          }
          .mobile-menu {
            background: rgba(0, 0, 0, 0.9);
            position: absolute;
            top: 60px;
            width: 100%;
            z-index: 1000;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .mobile-menu-list {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .mobile-menu-list li {
            padding: 15px;
            text-align: center;
          }
          .mobile-menu-list li a {
            text-decoration: none;
            color: #ffffff;
            font-size: 18px;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
